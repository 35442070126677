import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';

import AboutUsButton from './AboutUsButton';
import * as slideStyles from './Slideshow.module.scss';
import TextBlock from './TextBlock';
import TypedText from './TypedText';

const Home = ({ isActive }) => {
  return (
    <>
      <main>
        <StaticQuery
          query={graphql`
            {
              allPrismicHomepage {
                edges {
                  node {
                    data {
                      caption {
                        text
                      }
                    }
                  }
                }
              }
            }
          `}
          render={data => (
            <main className={`${slideStyles.slideWrapper} ${isActive && slideStyles.slideWrapperActive}`}>
              <div className={`${slideStyles.slideTextBlock} ${isActive && slideStyles.slideTextBlockActive}`}>
                <TextBlock>
                  <AboutUsButton mobile={false}></AboutUsButton>
                  <TypedText text={data.allPrismicHomepage.edges[0].node.data.caption.text} isActive={isActive}></TypedText>
                </TextBlock>
              </div>
            </main>
          )}
        ></StaticQuery>
      </main>
    </>
  );
};

Home.propTypes = {
  isActive: PropTypes.bool,
};

export default Home;

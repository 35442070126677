import PropTypes from 'prop-types';
import React from 'react';

import Layout from '../components/Layout';
import Slideshow from '../components/Slideshow';

const Client = ({ location }) => (
  <main>
    <Layout>
      <Slideshow location={location}></Slideshow>
    </Layout>
  </main>
);

Client.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Client;
